import * as React from 'react';
import {Outlet, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

export default function Application() {
    const navigate = useNavigate();
  
    React.useEffect(() => {
        const token = Cookies.get('token')
        if (!token || token == "") {
            navigate("/")
        }
    }, []);
    return (
        <Outlet />
    );
}