import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import CssBaseline from '@mui/material/CssBaseline';
import '@fontsource/roboto/300.css';
import Application from './Application';
import Labia from './record/Labia';
import Validate from './record/Validate';
import * as Sentry from "@sentry/react";
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
      mode: 'dark',
      info: {
          main: '#243C4D',
          contrastText: "#FFF"
      }
  },
  components: {
      MuiFab: {
          variants: [
              {
                  props: { variant: 'info' },
                  style: {
                      backgroundColor: '#243C4D !important',
                  },
              }
          ],
      },
      
      MuiButton: {
          variants: [
              {
                  props: { variant: 'outlined' },
                  style: {
                      backgroundColor: 'transparent !important',
                      color: "white !important",
                      border: "1px solid #76A9AC",
                      borderRadius: "100px",
                  },
              }
          ],
          styleOverrides: {
              root: {
                  borderRadius: "100px",
                  backgroundColor: '#76A9AC',
                  color: "#002657",
                  boxShadow: "none !important",
                  fontSize: "1rem !important",
                  fontWeight: "600 !important",
                  '&:hover': {
                      backgroundColor: '#578386',
                  },

              }
          }
      },
      MuiAutocomplete: {
          styleOverrides: {
              // Name of the slot
              root: {
                  // Some CSS
                  textTransform: 'none',
                  color: "white",
                  lineHeight: "1.4rem",
                  backgroundColor: "#FFFFFF20",
                  borderRadius: "10px",
                  '&::before': {
                      borderBottom: "0 !important",
                  },
                  '&::after': {
                      borderBottom: "0 !important",
                      border: "1px solid white !important",
                      top: 0,
                      borderRadius: "10px"
                  }
              },
          },
      },
      MuiTextField: {
          variants: [
              {
                  props: { variant: 'standard' },
                  style: {
                      textTransform: 'none',
                  },
              }
          ]
      },
      MuiFormLabel: {
          styleOverrides: {
              // Name of the slot
              root: {
                  left: "10px !important",
                  top: "0px !important",
                  marginBottom: "0px !important",
                  '-webkit-transform': "translate(0, 0) scale(1) !important",
                  transform: "translate(0, 0) scale(1) !important",
              }
          }
      },
      MuiInputLabel: {
          variants: [
              {
                  props: { variant: 'standard' },
                  style: {
                      position: "relative",
                      fontSize: "1.4rem",
                  },
              },

          ]
      },
      MuiInput: {
          styleOverrides: {
              // Name of the slot
              root: {
                  // Some CSS
                  textTransform: 'none',
                  color: "white",
                  lineHeight: "1.4rem",
                  backgroundColor: "#FFFFFF20",
                  borderRadius: "10px",
                  marginTop: "0 !important",
                  padding: 10,
                  '&::before': {
                      borderBottom: "0 !important",
                  },
                  '&::after': {
                      borderBottom: "0 !important",
                      border: "1px solid white !important",
                      top: 0,
                      borderRadius: "10px"
                  }
              },
          },
      },
      MuiOutlinedInput: {
          styleOverrides: {
              // Name of the slot
              root: {
                  // Some CSS
                  textTransform: 'none',
                  color: "white",
                  lineHeight: "1.4rem",
                  backgroundColor: "#FFFFFF20",
                  borderRadius: "10px",
                  marginTop: "0 !important",
                  '&::before': {
                      borderBottom: "0 !important",
                  },
                  '&::after': {
                      borderBottom: "0 !important",
                      border: "1px solid white !important",
                      top: 0,
                      borderRadius: "10px"
                  }
              },
          },
      }
  }
});

Sentry.init({
  dsn: "https://7565465d0af3e338a0b755cf59761688@o4507530299441152.ingest.de.sentry.io/4507530300817488",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      // Additional SDK configuration goes in here, for example:
      maskAllText: false,
      blockAllMedia: false,
    })
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/app\.labia\.pro/],
  // Session Replay
  replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />
  },
  {
    path: "/app",
    element: <Application />,
    children: [
      {
        index: true,
        element: <Labia/>,
      },
      {
        path: "/app/validate",
        element: <Validate/>,
      },
    ]
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <CssBaseline />
    <ThemeProvider theme={theme} style={{height: "100%"}}>
      <RouterProvider router={router}  style={{height: "100%"}}/>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a functio.n
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
