import React, { useEffect, useState, useRef } from "react";
import Stack from '@mui/material/Stack';
import Controls from './Controls';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import RecordPlugin from 'wavesurfer.js/dist/plugins/record.esm.js'
import WaveSurfer from 'wavesurfer.js'
import Zoom from '@mui/material/Zoom';
import { ButtonGroup, Fade, FormControlLabel, IconButton } from "@mui/material";
import logo from '../images/logo.png';
import Lottie from 'react-lottie';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import animationRecording from '../images/animation_recording.json';
import MicIcon from '@mui/icons-material/Mic';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Alert from '@mui/material/Alert';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import styles from '../App.module.css';
import AccountIcon from "../Account/AccountIcon";

const Recorder = ({ clean, finished, onClear, onStart }) => {
  const [open, setOpen] = React.useState(false);
  const [audioStateText, setAudioStateText] = useState("Pulsa para grabar");
  const [progress, setProgress] = useState("00:00");
  const [mic, setMic] = useState(null);
  const [inputs, setInputs] = useState([]);
  const waveformRef = useRef(null);
  const [record, setRecord] = useState(null);
  const [wavesurfer, setWavesurfer] = useState(null);
  const [start, setStart] = useState(false);
  const [recording, setRecording] = useState(false)
  const [recordingAnim, setRecordingAnim] = useState(false)
  const [clear, setClear] = useState(false)
  const cancelled = useRef(false)

  const recordingAnimation = {
    loop: true,
    autoplay: true,
    animationData: animationRecording,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  useEffect(() => {
  }, [clean]);


  let scrollingWaveform = true

  useEffect(() => {
    RecordPlugin.getAvailableAudioDevices().then((devices) => {
      var inputs = []
      devices.forEach((device) => {
        inputs.push({
          value: device.deviceId,
          label: device.label || device.deviceId,
        })
      })
      setInputs(inputs)
      if (inputs.length > 0) {
        setMic(inputs[0].value)
      }
    })
    return () => {
      if (wavesurfer != null) {
        wavesurfer.destroy()
      }
    };
  }, []);

  function createWaveSurfer() {
    // Create an instance of WaveSurfer
    console.log("Create an instance of WaveSurfer")
    if (wavesurfer != null) {
      wavesurfer.destroy()
    }

    let ws = WaveSurfer.create({
      container: waveformRef.current,
      height: 124,
      normalize: false,
      waveColor: "#002657",
      progressColor: "#002657",
      cursorColor: "#002657",
      cursorWidth: 0,
      barWidth: null,
      barGap: null,
      barRadius: null,
      barHeight: null,
      barAlign: "center",
      minPxPerSec: 1,
      fillParent: true,
      mediaControls: false,
      autoplay: false,
      interact: false,
      dragToSeek: false,
      hideScrollbar: false,
      audioRate: 1,
      autoScroll: true,
      autoCenter: true,
    })
    console.log("Initialize the Record plugin")

    let recordPlugin = ws.registerPlugin(RecordPlugin.create({ scrollingWaveform, renderRecordedAudio: true }))
    // Render recorded audio
    recordPlugin.on('record-end', (blob) => {
      // Send to server
      if (!cancelled.current) {
        setRecording(false)
        setRecordingAnim(false)
        setStart(false)
        finished(blob)
      }
    })

    recordPlugin.on('record-progress', (time) => {
      updateProgress(time)
    })
    setRecord(recordPlugin)
    setWavesurfer(ws)
    return recordPlugin
  }

  function updateProgress(time) {
    // time will be in milliseconds, convert it to mm:ss format
    const formattedTime = [
      Math.floor((time % 3600000) / 60000), // minutes
      Math.floor((time % 60000) / 1000), // seconds
    ]
      .map((v) => (v < 10 ? '0' + v : v))
      .join(':')
    setProgress(formattedTime)
  }

  function startRecord() {
    onStart()
    cancelled.current = false
    setClear(false)
    setRecording(true)
    setRecordingAnim(true)
    let record = createWaveSurfer()
    setStart(true)
    if (record.isRecording() || record.isPaused()) {
      record.stopRecording()
      return
    }
    const deviceId = mic.value
    record.startRecording({ deviceId }).then(() => {
      setAudioStateText("Grabando audio...");
    })
  }

  function pauseRecord() {
    if (record.isPaused()) {
      setRecording(true)
      setRecordingAnim(true)
      setAudioStateText("Grabando audio...");
      record.resumeRecording()
    } else {
      setRecording(false)
      setRecordingAnim(false)
      setAudioStateText("Pausado");
      record.pauseRecording() 
    }
  }

  function stopRecording() {
    setRecordingAnim(false)
    setRecording(false)
    record.stopRecording()
    setAudioStateText("Procesando audio");
  }

  function clearRecording() {
    cancelled.current = true
    setRecording(false)
    setRecordingAnim(false)
    setStart(false)
    onClear()
    setClear(true)
    setAudioStateText('Pulsa para grabar');
    record.stopRecording()
  }

  const handleChange = (event) => {
    setMic(event.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
  };
  const handleClick = () => {
    setOpen(true);
  };
  return (
    <Stack>
      <div style={{ position: "absolute", top: "10px", left: "20px" }}>
      <AccountIcon />
      </div>
      
      {inputs.length > 1 &&
        <ButtonGroup
          variant="contained"
          style={{ borderRadius: "30px", backgroundColor: "#ffffffbd", position: "absolute", top: "3%", right: "5%" }}
        >

          <Button
            style={{ borderRadius: "30px", color: "#002657", backgroundColor: "transparent", borderColor: "transparent", borderRight: "0px" }}
            size="small"
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleClick}
          >
            <ArrowDropDownIcon />
          </Button>
          <IconButton onClick={handleClick}
            style={{ borderRadius: "30px", color: "#002657", backgroundColor: "white" }}
          >
            <MicIcon />
          </IconButton>
        </ButtonGroup>
      }

      <Stack spacing={2} className={styles.marginTop}>
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
          <Typography style={{ zIndex: 1}} variant="h5" gutterBottom align="center" color={"white"}>{audioStateText}</Typography>
          <img id="logo" 
            src={logo} 
            alt="logo" 
            className={styles.marginLogo}
            style={{ width: "125px", zIndex: 1, position: recordingAnim ? "absolute" : "relative", marginTop: recordingAnim ? "44px" : "102px" }} 
            onClick={() => {if(!start) startRecord()} }
          />
          {recordingAnim && <Lottie
            options={recordingAnimation}
            height={"325px"}
            width={"325px"}
            style={{ zIndex: 0, transition: "all 1s ease-in-out"  }}
          />}
        </div>
        <div ref={waveformRef} style={{ display: "none" }} />
        <Zoom in={start} style={{ width: "100%", color: "white" }}>
          <Stack spacing={2}
            justifyContent="space-around"
            alignItems="center"
            style={{ width: "100%", color: "white", position: "absolute", bottom: "5%" }}>
            <Typography variant="h5">{progress}</Typography>
            <Controls
              style={{ width: "100%" }}
              onClear={clearRecording}
              onRecord={startRecord}
              onPause={pauseRecord}
              onFinish={stopRecording}
              recording={recording}
              recordInProgress={start}
            />
          </Stack>

        </Zoom>

        <Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
          <DialogTitle>Micrófono</DialogTitle>
          <DialogContent>
            <Box component="form" sx={{ display: 'flex', flexWrap: 'wrap' }}>
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={mic}
                  onChange={handleChange}
                >
                  {
                    inputs.map((input, i) => {
                      return (<FormControlLabel value={input.value} key={input.value} control={<Radio />} label={input.label} />)
                    })
                  }
                </RadioGroup>
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Guardar</Button>
          </DialogActions>
        </Dialog>

        <Zoom in={clear} style={{ transitionDelay: '500ms' }}>
          <Alert onClose={() => setClear(false)} severity="error" style={{ position: "absolute", bottom: "10%", width: "90%", marginLeft: "5%", marginRight: "5%" }}>Grabación cancelada</Alert>
        </Zoom>

      </Stack>
    </Stack>
  )
};

export default Recorder;