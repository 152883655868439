import { FormControlLabel, Radio, RadioGroup, Stack } from "@mui/material";
import api from "../../Services/Api";
import React, { useEffect, useState } from "react";
import * as Sentry from "@sentry/browser";

const Pipeline = ({selectPipeline, loaded}) => {
    const [pipeline, setPipeline] = useState(null)
    const [options, setOptions] = useState([]);

    useEffect(() => {
        api.fetchPipelines().then((result) => {
            if(result.length == 1) {
                setPipeline(result[0].label)
                selectPipeline(result[0].label, true)
            }
            setOptions(result);
            loaded();
          }).catch((error) => {
            Sentry.captureException(error);
            console.log(error)
          })

        }, []);

    return (
        <Stack spacing={2} alignItems="center" style={{ padding: "2rem" }}>
            <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
                onChange={(event) => {
                    setPipeline(event.target.value);
                    selectPipeline(event.target.value, false);
                    // event.target.value;
                }}
            >
                {
                    options.map((pipeline) => {
                        return (<FormControlLabel value={pipeline.label} control={<Radio />} label={pipeline.label} />)
                    })
                }
            </RadioGroup>
        </Stack>
    );
}

export default Pipeline;