import styles from '../App.module.css';
import { useState, useRef, useEffect } from 'react'
import { Box, Dialog, DialogActions, DialogContent, Button, Card, CircularProgress, Grid, Typography, Zoom } from '@mui/material';
import Fade from '@mui/material/Fade';
import MicIcon from '@mui/icons-material/Mic';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import { useLocation, useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import Cookies from 'js-cookie';
import api from '../Services/Api';
import Company from './components/Company';
import Deal from './components/Deal';
import Task from './components/Task';
import Contact from './components/Contact';
import Meeting from './components/Meeting';
import Pipeline from './components/Pipeline';
import * as Sentry from "@sentry/browser";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Lottie from 'react-lottie';
import animationProcessing from '../images/animation_processing.json';
import Mail from './components/Mail';
import Note from './components/Notes';

function Validate() {
    const processingAnimation = {
        loop: true,
        autoplay: true,
        animationData: animationProcessing,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    const { state } = useLocation();
    const { originalJson } = state;

    const navigate = useNavigate();
    var clear = false

    function setClear(val) {
        clear = val
    }

    //Errors handling
    const [error, setError] = useState(false)
    const [errorTitle, setErrorTitle] = useState("Error desconocido")
    const [errorType, setErrorType] = useState(null)
    const [userAlreadyExists, setUserAlreadyExists] = useState(false)
    const [loading, setLoading] = useState(false)
    const [processing, setProcessing] = useState(false)
    const [open, setOpen] = useState(false);
    //Success handling
    const [success, setSuccess] = useState(false)
    var validateOptions = useRef([])
    var json = useRef(originalJson)
    var index = useRef(0)

    useEffect(() => {
        processSuccess()
    }, []);

    const handleClose = () => {
        setOpen(false);
    };

    const sendJson = () => {
        clearBeforeSend()
        setProcessing(true)
        api.CreateDealFromJson(json.current).then((result) => {
            setProcessing(false)
            setLoading(false)
            setSuccess(true)
            setProcessing(false)
            navigate("/app", { state: { ok: true, fail: false } })
        }).catch((error) => {
            setProcessing(false)
            Sentry.captureException(error);
            setErrorType(error.error)
            validateOptions.current = [error.error]
            json.current = error.originalJson
            switch (error.error) {
                case api.ERROR.COMPANY:
                    json.current.company = {}
                    json.current.company.options = error.options
                    break;
                case api.ERROR.CONTACT:
                    json.current.contact = {}
                    json.current.contact.options = error.options
                    break;
                default:
                    json.current.options = error.options
            }
            processError()
        })
    }

    const clearBeforeSend = () => {
        setErrorTitle("")
        index.current = 0
        validateOptions.current = []
    }

    const processSuccess = () => {
        setLoading(true)
        setProcessing(false)
        var options = []
        if (json.current.deal) {
            options.push(api.ERROR.PIPELINE)
            options.push(api.ERROR.DEAL)
            options.push(api.ERROR.COMPANY)
            options.push(api.ERROR.CONTACT)
            if (json.current.task) {
                options.push(api.ERROR.TASK)
            }
            if (json.current.meeting) {
                options.push(api.ERROR.MEETING)
            }
            if (json.current.mail) {
                options.push(api.ERROR.MAIL)
                getUser();
            }
            if (json.current.note) {
                options.push(api.ERROR.NOTE)
            }
            validateOptions.current = options
            processError()
        } else {
            if (json.current.company) {
                options.push(api.ERROR.COMPANY)
            }
            if (json.current.contact) {
                options.push(api.ERROR.CONTACT)
            }
            if (json.current.task) {
                options.push(api.ERROR.TASK)
            }
            if (json.current.meeting) {
                options.push(api.ERROR.MEETING)
            }
            if (json.current.mail) {
                options.push(api.ERROR.MAIL)
                getUser();
            }
            if (json.current.note) {
                options.push(api.ERROR.NOTE)
            }
            validateOptions.current = options
            processError()
        }
    }

    const processError = () => {
        let error = validateOptions.current[index.current]
        console.log("Processing error", index.current, error)
        setProcessing(false)
        setError(true)
        setErrorType(error)
        switch (error) {
            case api.ERROR.PIPELINE:
                setErrorTitle("Seleccione un pipeline")
                break;
            case api.ERROR.COMPANY:
                setErrorTitle("Valide los datos de la compañía")
                break;
            case api.ERROR.CONTACT:
                setErrorTitle("Valide los datos del contacto")
                break;
            case api.ERROR.TASK:
                setErrorTitle("Valide los datos de la tarea")
                break;
            case api.ERROR.MEETING:
                setErrorTitle("Valide los datos de la reunión")
                break;
            case api.ERROR.DEAL:
                setErrorTitle("Valide los datos del negocio")
                break;
            case api.ERROR.MAIL:
                setErrorTitle("Valide los datos del correo")
                break;
            case api.ERROR.NOTE:
                setErrorTitle("Valide los datos de la nota")
                break;
            default:
                Sentry.captureException(error);
                if (error.status == 401) {
                    Cookies.set('token', "", { expires: 7, secure: true });
                    navigate("/")
                } else {
                    navigate("/app", { state: { ok: false, fail: true } })
                }
                console.log("Error desconocido")
        }
    }


    const createContact = () => {
        if (json.current.contact.create) {
            api.createContact(json.current.contact).then((result) => {
                console.log("Setting contact id", result)
                json.current.contact = { id: result.id, create: false, options: [result] }
                if (json.current.meeting) {
                    json.current.meeting.attendees[0] = result
                    json.current.meeting.options.push(result)
                }
                index.current = index.current + 1
                processError()
                setLoading(false)
            }).catch((error) => {
                setLoading(false)
                json.current.contact.create = false;
                setUserAlreadyExists(true)
                Sentry.captureException(error);
            })
        } else {
            index.current = index.current + 1
            setLoading(false)
            processError()
        }
    }

    const getUser = () => {
        api.getUserData().then((response) => {
            json.current.mail.useremail = response.email
            json.current.mail.username = response.name
        }).catch((error) => {
        });
    }


    return (
        <Stack spacing={2} className={styles.BackgroundGlobalNoStyle}>
            {index.current > 0 &&
                <div style={{ width: "100%", }}>
                    <IconButton onClick={() => { index.current = index.current - 1; processError(); }} style={{ color: "white" }}>
                        <ArrowBackIcon style={{ fontSize: "35px" }} />
                    </IconButton>
                </div>

            }
            <Fade in={!processing}>
                <Grid container style={{ flexDirection: 'column', alignItems: 'center', color: "white", marginTop: 0 }}>
                    <Stack spacing={2} alignItems="center" style={{ padding: "1rem", width: "100%" }}>

                        <Typography variant="h5" gutterBottom align="center">{errorTitle}</Typography>

                        {errorType == api.ERROR.PIPELINE &&
                            <Pipeline
                                loaded={() => setLoading(false)}
                                selectPipeline={(pipeline, justOne) => {
                                    json.current.deal.pipeline = pipeline
                                    var options = []
                                    options.push(api.ERROR.PIPELINE)
                                    options.push(api.ERROR.DEAL)
                                    options.push(api.ERROR.COMPANY)
                                    options.push(api.ERROR.CONTACT)
                                    if (json.current.task) {
                                        options.push(api.ERROR.TASK)
                                    }
                                    if (json.current.meeting) {
                                        options.push(api.ERROR.MEETING)
                                    }
                                    validateOptions.current = options
                                    if (justOne) {
                                        setLoading(true)
                                        index.current = index.current + 1
                                        processError()
                                    }
                                }
                                } />
                        }

                        {errorType == api.ERROR.COMPANY &&
                            <Company
                                loaded={() => setLoading(false)}
                                company={json.current.company} selectCompany={company => json.current.company = company} setCompany={company => json.current.company = company} />
                        }

                        {errorType == api.ERROR.DEAL &&
                            <Deal
                                loaded={() => setLoading(false)}
                                deal={json.current.deal} createDeal={deal => json.current.deal = deal} />
                        }

                        {errorType == api.ERROR.TASK &&
                            <Task
                                loaded={() => setLoading(false)}
                                task={json.current.task} setTask={task => json.current.task = task} />
                        }

                        {errorType == api.ERROR.MEETING &&
                            <Meeting
                                loaded={() => setLoading(false)}
                                meeting={json.current.meeting} createMeeting={meeting => json.current.meeting = meeting} />
                        }

                        {errorType == api.ERROR.CONTACT &&
                            <Contact
                                loaded={() => setLoading(false)}
                                contact={json.current.contact} setContact={contact => json.current.contact = contact} userAlreadyExists={userAlreadyExists} />
                        }

                        {errorType == api.ERROR.MAIL &&
                            <Mail 
                                loaded={() => setLoading(false)}
                                mail={json.current.mail} setMail={mail => json.current.mail = mail} />
                        }

                        {errorType == api.ERROR.NOTE &&
                            <Note loaded={() => setLoading(false)} note={json.current.note} setNote={note => json.current.note = note} />
                        }

                        <Stack direction="column" spacing={2} style={{ width: "100%" }}>
                            <Box sx={{ m: 1, position: 'relative' }}>
                                <Button disabled={loading} fullWidth variant="contained" color="primary" onClick={() => {
                                    setLoading(true)
                                    if (index.current + 1 < validateOptions.current.length) {
                                        if (errorType == api.ERROR.CONTACT) {
                                            createContact()
                                        } else {
                                            index.current = index.current + 1
                                            processError()
                                        }
                                    } else {
                                        sendJson();
                                        setProcessing(true);
                                        setError(false);
                                    }
                                }}>
                                    {index.current < validateOptions.current.length - 1 && "Siguiente"}
                                    {index.current == validateOptions.current.length - 1 && "Finalizar"}
                                    {loading && <CircularProgress
                                        size={24}
                                        sx={{
                                            color: "white",
                                            position: 'absolute',
                                            top: '50%',
                                            right: '5%',
                                            marginTop: '-12px',
                                            marginLeft: '-12px',
                                        }}
                                    />
                                    }
                                </Button>
                            </Box>
                            <Button variant="outlined" color="error" onClick={() => { setOpen(true) }}>Cancelar</Button>

                        </Stack>

                        <Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
                            <DialogContent>
                                ¿Seguro que deseas cancelar?
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose}>cerrar</Button>
                                <Button onClick={() => navigate("/app")}>OK</Button>
                            </DialogActions>
                        </Dialog>
                    </Stack>
                </Grid>
            </Fade>

            <Fade in={processing}>
                <Stack spacing={2} className={styles.marginTop} style={{ position: "absolute", left: 0, right: 0 }}>
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>

                        <Typography variant="h5" gutterBottom align="center" style={{ color: "white" }}>Enviando datos</Typography>
                        <Lottie
                            options={processingAnimation}
                            height={"125px"}
                            width={"125px"}
                            style={{ marginTop: "102px" }}
                        />

                    </div>
                </Stack>
            </Fade>
        </Stack>
    );
}

export default Validate;
