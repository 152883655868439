import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Popover } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import AccountComponent from './AccountComponent';
import Api from '../Services/Api';
import * as Sentry from "@sentry/react";

const AccountIcon = () => {
    const [open, setOpen] = React.useState(false);
    const [user, setUser] = React.useState(null)
    const navigate = useNavigate();

    const handleClick = (event) => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        Api.getUserData().then((response) => {
            setUser(response)
            Sentry.setUser({ email: response.email });
        }).catch((error) => {
            navigate("/")
        });
    }, []);

    return (
        <>
            <IconButton variant="contained" color="white" onClick={handleClick}
                size="large"
                 elevation={3}>
                <AccountCircleIcon style={{ color: 'white', fontSize: "40px" }} />
            </IconButton>

            <Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
                <DialogContent>
                    <AccountComponent user={user} onLogout={handleClose}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>cerrar</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default AccountIcon;