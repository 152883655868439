import { FormControl, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';
import React from 'react';

const Task = ({task, setTask, loaded}) => {
    const [newTask, setNewTask] = React.useState(task);
    loaded();
    return (
        <Stack spacing={2} fullWidth style={{ width: "100%" }}>
            <TextField
                fullWidth
                id="standard-basic"
                label="Asunto"
                variant="standard"
                defaultValue={task.subject}
                onChange={(event) => {
                    const newData = { subject: event.target.value, priority: newTask.priority, dueDate: newTask.dueDate, hs_task_type: newTask.hs_task_type, description: newTask.description };
                    setNewTask(newData);
                    setTask(newData);
                }}
            />

            <FormControl fullWidth>
                <InputLabel id="priority-label">Prioridad</InputLabel>
                <Select
                    labelId="priority-label"
                    id="priority-select"
                    label="Prioridad"
                    defaultValue={task.priority}
                    onChange={(event) => {
                        const newData = { subject: newTask.subject, priority: event.target.value, dueDate: newTask.dueDate, hs_task_type: newTask.hs_task_type, description: newTask.description };
                        setNewTask(newData);
                        setTask(newData);
                    }}
                >
                    <MenuItem value="LOW">Baja</MenuItem>
                    <MenuItem value="MEDIUM">Media</MenuItem>
                    <MenuItem value="HIGH">Alta</MenuItem>
                </Select>
            </FormControl>

            <TextField
                fullWidth
                id="dueDate"
                label="Fecha de vencimiento (en días)"
                type="number"
                variant="standard"
                defaultValue={task.dueDate}
                onChange={(event) => {
                    const newData = { subject: newTask.subject, priority: newTask.priority, dueDate: event.target.value, hs_task_type: newTask.hs_task_type, description: newTask.description };
                    setNewTask(newData);
                    setTask(newData);
                }}
            />

            <FormControl fullWidth>
                <InputLabel id="hs-task-type-label">Tipo de tarea</InputLabel>
                <Select
                    labelId="hs-task-type-label"
                    id="hs-task-type-select"
                    label="Tipo de tarea"
                    defaultValue={task.hs_task_type}
                    onChange={(event) => {
                        const newData = { subject: newTask.subject, priority: newTask.priority, dueDate: newTask.dueDate, hs_task_type: event.target.value, description: newTask.description };
                        setNewTask(newData);
                        setTask(newData);
                    }}
                >
                    <MenuItem value="CALL">Llamada</MenuItem>
                    <MenuItem value="EMAIL">Correo electrónico</MenuItem>
                    <MenuItem value="TODO">Tarea</MenuItem>
                </Select>
            </FormControl>

            <TextField
                fullWidth
                id="description"
                label="Descripción"
                variant="standard"
                defaultValue={task.description}
                onChange={(event) => {
                    const newData = { subject: newTask.subject, priority: newTask.priority, dueDate: newTask.dueDate, hs_task_type: newTask.hs_task_type, description: event.target.value };
                    setNewTask(newData);
                    setTask(newData);
                }}
            />
        </Stack>
    );
}

export default Task;