import { Stack, TextField } from '@mui/material';
import React from 'react';

const Deal = ({deal, createDeal, loaded}) => {
    const [newDeal, setNewDeal] = React.useState(deal);
    loaded();

    const newData = (field, value) => {
        setNewDeal({ ...newDeal, [field]: value });
        createDeal({ ...newDeal, [field]: value });
    };

    return (
        <Stack spacing={2} fullWidth style={{ width: "100%" }}>
            <TextField
                fullWidth
                id="dealName"
                label="Nombre del negocio"
                variant="standard"
                defaultValue={deal.dealname}
                onChange={(event) => newData("dealname", event.target.value)}
            />

            <TextField
                fullWidth
                id="dealValue"
                label="Valor del negocio"
                variant="standard"
                defaultValue={deal.amount}
                onChange={(event) => newData("amount", event.target.value)}
            />

            <TextField
                fullWidth
                id="dealClose"
                label="Fecha de cierre del negocio"
                type="date"
                variant="standard"
                InputLabelProps={{
                    shrink: true,
                }}
                defaultValue={deal.estimatedDateClosed}
                onChange={(event) => newData("estimatedDateClosed", event.target.value)}
            />
        </Stack>
    );
}

export default Deal;