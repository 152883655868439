import styles from '../App.module.css';
import Recorder from '../components/Recorder';
import { useState, useRef, useEffect } from 'react'
import { Alert, Card, Typography, Zoom } from '@mui/material';
import Fade from '@mui/material/Fade';
import Stack from '@mui/material/Stack';
import DoneIcon from '@mui/icons-material/Done';
import CheckIcon from '@mui/icons-material/Check';
import { useLocation, useNavigate } from 'react-router-dom';
import api from '../Services/Api';
import * as Sentry from "@sentry/browser";
import Lottie from 'react-lottie';
import animationProcessing from '../images/animation_processing.json';
import animationLoading from '../images/animation_loading.json';
import FeedbackIcon from '@mui/icons-material/Feedback';
function Labia() {
  const processing = {
    loop: true,
    autoplay: true,
    animationData: animationProcessing,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };
  const { state } = useLocation();
  const crmUpdated = state != undefined ? state.ok : false
  const failed = state != undefined ? state.fail : false
  const navigate = useNavigate();
  var clear = false

  function setClear(val) {
    clear = val
  }

  const [process, setProcess] = useState(false)
  //Errors handling
  const [error, setError] = useState(failed)
  //Success handling
  const [success, setSuccess] = useState(crmUpdated)
  const [processingText, setProcessingText] = useState("Procesando audio...")
  var json = useRef([])

  useEffect(() => {
    const socket = api.socket;
    function disconnect() {
      console.log('disconnected');
      socket.disconnect();
      socket.close();
    }
    function progressUpdate(progress) {
      console.log(progress);
      updateProgress(progress);
    }

    function textCallback(response) {
      console.log('textCallback');
      resolve(response);
    }

    function errorCallback(error) {
      console.log(error);
      if (error.code == "ERR_BAD_REQUEST" && error.status != 401) {
        api.processError(error.response.data, reject);
      } else {
        reject(error.response)
      }
    }
    socket.on('disconnect', disconnect);
    socket.on('progress', progressUpdate);
    socket.on('text', textCallback);
    socket.on('error', errorCallback);
    return () => {
      socket.off('disconnect', disconnect);
      socket.off('progress', progressUpdate);
      socket.off('text', textCallback);
      socket.off('error', errorCallback);
    };
  }, []);

  const sendAudio = (audio) => {
    setClear(false)
    setProcess(true)
    api.UploadAudio(audio, onUploadProgress).then((response) => {
      const socket = api.socket;
      if (socket.connected) {
        socket.emit('text', { data: { text: response.text } });
      } else {
        socket.connect();
        socket.on('connect', () => {
          console.log('connected');
          socket.emit('text', { data: { text: response.text } });
        });
      }
    }).catch((error) => {
      Sentry.captureException(error);
      setError(true)
      setProcess(false)
    })
  }

  const resolve = (json) => {
    navigate("/app/validate", { state: { originalJson: json } })
  }

  const reject = (error) => {
    console.log(error)
    Sentry.captureException(error);
    //json.current = error.originalJson
    setError(true)
    setProcess(false)
  }

  const updateProgress = (progress) => {
    setProcessingText(progress)
  }

  const onUploadProgress = (progressEvent) => {
    var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
    console.log(percentCompleted)
    if (percentCompleted == 100) {
      setProcessingText("Procesando audio...")
    } else {
      setProcessingText("Procesando audio... " + percentCompleted + "%")
    }
  }


  return (
    <Stack className={styles.BackgroundGlobalNoStyle}>
      {success &&
        <Alert style={{
          backgroundColor: "#366E6B", color: "white", position: "absolute", width: "90%", left: "5%", right: "5%",
          bottom: "5%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", textAlign: "center"
        }}
          icon={<CheckIcon style={{ color: "white" }} fontSize="inherit" />}>
          Audio enviado correctamente
        </Alert>
      }

      {!process &&
        <Recorder finished={sendAudio} onClear={() => setClear(true)} onStart={() => {
          setSuccess(false)
          setError(false)
        }} />
      }

      {process && <Fade in={process}>
        <Stack spacing={2} className={styles.marginTop}>
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>

            <Typography variant="h5" gutterBottom align="center" style={{ color: "white" }}>{processingText}</Typography>
            <Lottie
              options={processing}
              height={"125px"}
              width={"125px"}
              style={{ marginTop: "102px" }}
            />

          </div>
        </Stack>
      </Fade>
      }

      {error &&
        <Alert style={{
          backgroundColor: "white", color: "red", position: "absolute", width: "90%", left: "5%", right: "5%",
          bottom: "5%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", textAlign: "center"
        }}
          icon={<FeedbackIcon style={{ color: "red" }} fontSize="inherit" />}
        >
          Error al enviar el audio
        </Alert>
      }
    </Stack>
  );
}

export default Labia;
