import { Autocomplete, Stack, TextField, Typography } from '@mui/material';
import React from 'react';
import ContactAutocomplete from './ContactAutocomplete';

const Meeting = ({meeting, createMeeting, loaded}) => {
    const [newMeeting, setNewMeeting] = React.useState(meeting);
    loaded();
    if (newMeeting.attendees != null && newMeeting.options == null) {
        newMeeting.options = [];
        newMeeting.attendees.forEach((attendee) => {
            newMeeting.options.push(attendee);
        });
    }
    return (
        <Stack spacing={2} fullWidth style={{ width: "100%" }}>
            <TextField
                fullWidth
                id="meetingTitle"
                label="Título de la reunión"
                variant="standard"
                defaultValue={meeting.title}
                onChange={(event) => {
                    let meeting = { title: event.target.value, startDate: newMeeting.startDate, endTime: newMeeting.endTime, location: newMeeting.location, reminder: newMeeting.reminder, description: newMeeting.description, internalNote: newMeeting.internalNote, attendees: newMeeting.attendees }
                    setNewMeeting(meeting);
                    createMeeting(meeting);
                }}
            />

            <TextField
                fullWidth
                id="meetingStartDate"
                label="Fecha de inicio"
                type="datetime-local"
                InputLabelProps={{
                    shrink: true,
                }}
                variant="standard"
                defaultValue={meeting.startDate.replace("Z", "")}
                onChange={(event) => {
                    let meeting = { title: newMeeting.title, startDate: event.target.value, endTime: newMeeting.endTime, location: newMeeting.location, reminder: newMeeting.reminder, description: newMeeting.description, internalNote: newMeeting.internalNote, attendees: newMeeting.attendees }
                    setNewMeeting(meeting);
                    createMeeting(meeting);
                }}
            />

            <TextField
                fullWidth
                id="meetingEndDate"
                label="Fecha de finalización"
                type="datetime-local"
                InputLabelProps={{
                    shrink: true,
                }}
                variant="standard"
                defaultValue={meeting.endTime.replace("Z", "")}
                onChange={(event) => {
                    let meeting = { title: newMeeting.title, startDate: newMeeting.startDate, endTime: event.target.value, location: newMeeting.location, reminder: newMeeting.reminder, description: newMeeting.description, internalNote: newMeeting.internalNote, attendees: newMeeting.attendees }
                    setNewMeeting(meeting);
                    createMeeting(meeting);
                }}
            />

            <TextField
                fullWidth
                id="meetingLocation"
                label="Lugar"
                variant="standard"
                defaultValue={meeting.location}
                onChange={(event) => {
                    let meeting = { title: newMeeting.title, startDate: newMeeting.startDate, endTime: newMeeting.endTime, location: event.target.value, reminder: newMeeting.reminder, description: newMeeting.description, internalNote: newMeeting.internalNote, attendees: newMeeting.attendees }
                    setNewMeeting(meeting);
                    createMeeting(meeting);
                }}
            />

            <TextField
                fullWidth
                id="meetingReminder"
                label="Recordatorio"
                variant="standard"
                defaultValue={meeting.reminder}
                onChange={(event) => {
                    let meeting = { title: newMeeting.title, startDate: newMeeting.startDate, endTime: newMeeting.endTime, location: newMeeting.location, reminder: event.target.value, description: newMeeting.description, internalNote: newMeeting.internalNote, attendees: newMeeting.attendees };
                    setNewMeeting(meeting);
                    createMeeting(meeting);
                }}
            />

            <TextField
                fullWidth
                id="meetingDescription"
                label="Descripción"
                variant="standard"
                defaultValue={meeting.description}
                onChange={(event) => {
                    let meeting = { title: newMeeting.title, startDate: newMeeting.startDate, endTime: newMeeting.endTime, location: newMeeting.location, reminder: newMeeting.reminder, description: event.target.value, internalNote: newMeeting.internalNote, attendees: newMeeting.attendees };
                    setNewMeeting(meeting)
                    createMeeting(meeting);
                }}
            />

            <TextField
                fullWidth
                id="meetingInternalNote"
                label="Nota interna"
                variant="standard"
                defaultValue={meeting.internalNote}
                onChange={(event) => {
                    let meeting = { title: newMeeting.title, startDate: newMeeting.startDate, endTime: newMeeting.endTime, location: newMeeting.location, reminder: newMeeting.reminder, description: newMeeting.description, internalNote: event.target.value, attendees: newMeeting.attendees };
                    setNewMeeting(meeting)
                    createMeeting(meeting);
                }}
            />
            { meeting.attendees != null && 
                <Typography variant="h6" gutterBottom align="center">Asistentes</Typography>
            }
            {meeting.attendees != null && meeting.attendees.map((attendee, index) => {
                return (
                    <ContactAutocomplete opts={meeting.options} contact={attendee} setContact={(contact) => { 
                        meeting.attendees[index] = contact; 
                        let newMeeting2 = { title: newMeeting.title, startDate: newMeeting.startDate, endTime: newMeeting.endTime, location: newMeeting.location, reminder: newMeeting.reminder, description: newMeeting.description, internalNote: newMeeting.internalNote, attendees: meeting.attendees }
                        setNewMeeting(newMeeting2)
                        createMeeting(newMeeting2);
                    }} />
                )
            })
            }
        </Stack>
    );
}

export default Meeting;